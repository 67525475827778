import React from "react";
import Header from './Header';
import SiderBar from './SiderBar';
import Breadcrumbs from './Breadcrumbs';
import { routes } from "../../service/routes";
import Footer from "./Footer";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { connect } from "react-redux";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

class SDNLayout extends React.Component {
  render() {
    return (
      <Layout style={{height: '100%'}}>
        <Header/>
        <Layout style={{height: 'calc(100% - 64px - 41px)'}}>
          <SiderBar />
          <Layout style={{height: '100%', marginLeft: this.props.showSiderBar ? 250 : 80}}>
            <Breadcrumbs route={window.location.hash.slice(1)}/>
            <Content style={{ padding: '0 16px', overflowX: 'hidden', overflowY: 'auto', height: 'calc(100% - 41px)' }}>
                <Switch>
                  <Route exact path="/" render={() => <Redirect to="SDNPowerAdmin/SDNPowerOverview"/>} />
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                    {/*<Redirect from="/" to="/misc/404" />*/}
                </Switch>
            </Content>
          </Layout>
        </Layout>
        <Footer />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    showSiderBar: state.layout.showSiderBar
  }
}

export default connect(mapStateToProps)(SDNLayout);
